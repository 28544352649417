//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Errors from '@/shared/error/errors';
import { i18n } from '@/i18n';

const { fields } = UserModel;

const formSchema = new FormSchema([
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.avatarsProfile,
  fields.countryCode,
  fields.lang,
  fields.accountType,
]);

export default {
  name: 'app-profile-form-page',
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      CCode: 'SA',
      defaultImg:
        '../../../../public/images/defaultAvatar.svg',
      url: '../../../../public/images/defaultAvatar.svg',
      phoneNumber: '051 234 5678',
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      saveLoading: 'auth/loadingUpdateProfile',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    fields() {
      return fields;
    },
    isRTL() {
      return localStorage.getItem('language') == 'ar'
    },
    isCCode() {
      if (!this.model.countryCode) {
        return this.CCode;
      } else {
        return this.model.countryCode;
      }
    },
  },
  async created() {
    this.model = formSchema.initialValues(this.currentUser);
    // console.log(this.model)

    let avatar = [];

    if (
      process.env.VUE_APP_ENVIRONMENT === 'staging' ||
      process.env.VUE_APP_ENVIRONMENT == 'localhost'
    ) {
      avatar = [
        {
          id: '',
          name: 'defaultAvatar.svg',
          privateUrl: '',
          publicUrl:
            'https://firebasestorage.googleapis.com/v0/b/saryah-staging.appspot.com/o/defaultAvatar%2FdefaultAvatar.svg?alt=media&token=661b4f2d-2ef2-4b45-ab84-c3c008d2e18c',
        },
      ];
    } else if (
      process.env.VUE_APP_ENVIRONMENT === 'production'
    ) {
      avatar = [
        {
          id: '',
          name: 'defaultAvatar.svg',
          privateUrl: '',
          publicUrl:
            'https://firebasestorage.googleapis.com/v0/b/saryah-31e36.appspot.com/o/defaultAvatar%2FdefaultAvatar.svg?alt=media&token=cfff16d3-2ebd-414c-9aea-4295499369c8',
        },
      ];
    }

    // to check if there is no avatar then set the default one

    if (!this.model.avatars[0]) {
      this.model.avatars = avatar;
    }
  },

  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile',
    }),
    i18n(code) {
      return i18n(code);
    },
    doReset() {
      this.model = formSchema.initialValues(
        this.currentUser,
      );
    },
    onError(error) {
      Errors.showMessage(error);
      this.loading = false;
    },
    onUpdate(data) {
      this.CCode = data.countryCode;
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      this.model.countryCode = this.CCode;
      this.model.lang = localStorage.getItem('language');
      this.model.accountType = this.currentUser.accountType;
      const values = formSchema.cast(this.model);
      this.doUpdateProfile(values);
    },
  },
};
